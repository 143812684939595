
#chatView {

    position: absolute;
    top: 0;
    right: 0;

    width: calc(100% - 400px);
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: white;
    display: flex;
    flex-direction: column;

    margin-top: 40px;
    /* border: 1px solid red; */
}

#chatViewContent {

    flex: 1;
}



#chatView.hidden {
    display:none;
}

#chatView #chatViewTopBar {

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;

    height: 60px;
    padding: 0px 20px;
    padding-top: 10px;

    border-radius: 0px 0px 10px 10px;

    z-index: 2;
    background-color: rgba(9,121,12,1);
    color: white;

}

#chatView #chatViewTopBar #chatViewTopBar-Right {

    font-size: 1.5em;
    cursor: pointer;
    display: none;
    transition: all 0.1s ease-in-out;

}
#chatView #chatViewTopBar #chatViewTopBar-Right:active {

    transform: scale(0.85);

}

#chatView #chatViewBottomBar {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;

    left: 400px;
    height: 60px;
    padding: 0px 20px;
    padding-bottom: 10px;

    border-radius: 10px 10px 0px 0px;

    background-color: rgba(9,121,12,1);
    color: white;

}

#chatViewContent #convo {

    flex: 1;
    /* border: 1px solid red; */
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 50px;
}

#chatView #chatViewBottomBar input {

    width: 95%;
    height: 40px;

    background: none;
    border: none;
    border-bottom: 1px solid white;
    color: white;
    text-align: left;
    padding: 0px 10px;

}

#chatView #chatViewBottomBar input::placeholder {
    color: rgba(255,255,255,0.5);
    user-select: none;
}

#chatView #chatViewBottomBar input:focus {
    text-decoration: none;
    outline-width: 0;
}

#chatView #chatViewBottomBar #chatViewBottomBar-Buttons {

    width: 50px;

    font-size: 1.5em;
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    transform: translateY(5px);

}

#chatView #chatViewBottomBar #chatViewBottomBar-Buttons #sendButton {
    
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    user-select: none;

}
#chatView #chatViewBottomBar #chatViewBottomBar-Buttons #sendButton:active {
    
    transform: scale(0.85);

}

@media screen and (max-width: 1000px) {

    #chatView {
        width: calc(60%);
    }

    #chatView #chatViewBottomBar {
        left: 40%;
    }

}

@media screen and (max-width: 600px) {
    #chatView {

        width: 100%;
        /* z-index: 2; */

    }

    #chatView #chatViewBottomBar {
        left: 0;
    }

    #chatView #chatViewTopBar #chatViewTopBar-Right {
        display: block;
    }
}