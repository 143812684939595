#contactsListContainer {

    position: absolute;
    top: 0;
    left: 0;
    background: #f8f8f8;
    height: 100%;
    width:400px;
    overflow-y: scroll;
    padding-top: 50px;
    transform: translateY(-10px);
    border-right: 1px  solid rgb(0,0,0, 0.1);
}

#contactsListContainer #topBar {
    position:sticky;
    left: 0;
    top: 0;
    color: black;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0,0.2) 0%, rgba(0,0,0,0) 100%, rgba(0,0,0,0) 100%);
    padding-top: 20px;
}

#contactsListContainer #topBar #searchInput {
    
    height: 30px;
    border-radius: 20px;
    border: none;
    flex: 1;
    text-align: left;
    padding: 0px 20px;

}

#contactsListContainer #topBar #newEntryButton {
    font-size: 30px;
    color: rgba(9,121,12,1);
    width: 50px;
    height: 40px;
    background: none;
    border: none;
    padding: 0;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    transform: translateY(3px);
}

#contactsListContainer #topBar #newEntryButton:active {
    transform: scale(0.85) translateY(3px);

}

#contactsListContainer .list .contact {

    flex: 1;
    height:100px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    cursor: pointer;

}

#contactsListContainer .list .contact.contactSelected {
    background-color: rgba(9,121,12,1);
    color: white;
}

#contactsListContainer .list .contact .innerContainer {

    display: flex;
    flex-direction: row;
    flex: 1;
    /* border: 1px solid black; */
    margin: 10px;

}

#contactsListContainer .list .contact .innerContainer .infoColumn {

    display: flex;
    flex: 1;
    /* border: 1px solid blue; */
    flex-direction: column;
    padding: 10px;
    justify-content: space-around;
    align-items: flex-start;

}

#contactsListContainer .list .contact .innerContainer .infoColumn .contactName {

    font-size: 1.2em;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;


}


#contactsListContainer .list .contact .innerContainer .contactProfilePic {

    height: 100%;
    width: 20%;
    /* border: 1px solid red; */


}


.list {
    padding-bottom: 100px;
}

@media screen and (max-width: 1000px) {

    #contactsListContainer {
        width: 40%;
    }

}

@media screen and (max-width: 600px) {
    #contactsListContainer {
        width: 100vw;
        border: none;
    }
}
