@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

#signInPage *:not(input) {
	box-sizing: border-box;
    user-select: none;
}

#signInPage {
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	/* margin: -20px 0 50px; */
}

#signInPage h1 {
	font-weight: bold;
	margin: 0;
}

#signInPage h2 {
	text-align: center;
}



#signInPage p:not(.serverMessage) {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.form-container .serverMessage {
    color: #ff0000;
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 0.5px;
    margin: 10px;
}

#signInPage span {
	font-size: 12px;
}

#signInPage a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

#signInPage button {
	border-radius: 20px;
	border: 1px solid rgba(9,121,12,1);
	background-color: rgba(9,121,12,1);
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
    margin-top:10px;
    cursor: pointer;
}

#signInPage button:active {
	transform: scale(0.95);
}

#signInPage button:focus {
	outline: none;
}

#signInPage button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

#signInPage form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

#signInPage input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
    border-radius: 20px;;
}

#signInPage .container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
}

#signInPage .form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}



@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}





#signInPage .overlay {
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,12,1) 0%, rgba(0,255,169,1) 100%); */
    /* background: -webkit-linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,121,12,1) 0%, rgba(0,255,169,1) 100%); */

    background: rgba(9,121,12,1);
    
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}



#signInPage .overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.disabledSubmitButton {
    
    color: white;
    cursor: not-allowed;
    filter: opacity(0.75) grayscale(100%);
}



@media screen and (orientation: landscape) {
    
    #signInPage .container {
        width: 100vh;
	    height: 70vh;
    }

    #signInPage .sign-in-container {
        left: 0;
        width: 50%;
        z-index: 2;
    }
    
    
    
    #signInPage .sign-up-container {
        left: 0;
        width: 50%;
        opacity: 0;
        z-index: 1;
    }

    #signInPage .overlay-container {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
    }

    #signInPage .container.right-panel-active .overlay-container{
        transform: translateX(-100%);
    }

    #signInPage .container.right-panel-active .sign-in-container {
        transform: translateX(100%);
    }

    #signInPage .container.right-panel-active .sign-up-container {
        transform: translateX(100%);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
    }

    #signInPage .container.right-panel-active .overlay {
        transform: translateX(50%);
    }

    #signInPage .overlay-left {
        transform: translateX(-50%);
    }
    
    #signInPage .container.right-panel-active .overlay-left {
        transform: translateX(0);
    }
    
    #signInPage .overlay-right {
        right: 0;
        transform: translateX(0);
    }
    
    #signInPage .container.right-panel-active .overlay-right {
        transform: translateX(50%);
    }

}

@media screen and (orientation: portrait) {
    
    #signInPage .container {

        width: 90vw;
	    min-height: 80vh;

    }

    #signInPage .sign-in-container {
        left: 0;
        top: 50%;
        width: 100%;
        height: 50%;
        z-index: 2;
    }
    
    
    
    #signInPage .sign-up-container {
        left: 0;
        top: 50%;
        width: 100%;
        height: 50%;
        opacity: 0;
        z-index: 1;
    }

    #signInPage .overlay-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        overflow: hidden;
        transition: transform 0.6s ease-in-out;
        z-index: 100;
    }

    #signInPage .container.right-panel-active .overlay-container{
        transform: translateY(100%);
    }

    #signInPage .container.right-panel-active .sign-in-container {
        transform: translateY(-100%);
    }

    #signInPage .container.right-panel-active .sign-up-container {
        transform: translateY(-100%);
        opacity: 1;
        z-index: 5;
        animation: show 0.6s;
    }

    #signInPage .container.right-panel-active .overlay {
        transform: translateY(0);
    }

    #signInPage .overlay-left {
        right: 0;
        transform: translateY(150%);

    }
    
    #signInPage .container.right-panel-active .overlay-left {
        /* transform: translateY(0);
        color: green;
        z-index: 999; */
        /* right: 0; */
        transform: translateY(0%);
    }
    
    #signInPage .overlay-right {
        right: 0;
        transform: translateY(0%);
    }
    
    #signInPage .container.right-panel-active .overlay-right {
        /* transform: translateY(20%); */
        /* right: 100%; */
        transform: translateY(-150%);
    }

}

